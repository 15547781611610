import {Tabs, Tab, Fade, Box, makeStyles, Link} from '@material-ui/core';
import {useState} from 'react';
import Page from './Page';
import {ExternalLink} from '../../nav/common';

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%',
  },
  markdown: {
    marginTop: theme.spacing(1),
  },
  tab: {
    width: '200px',
  },
  tabLink: {
    backgroundColor: '#0E8BED',
    color: '#FFFFFF',
    borderRadius: '20px',
    marginLeft: '15px',
    height: '30px',
    minHeight: '30px',
    marginTop: '8px',
  },
}));

interface TabbedSectionProps {
  tabs: readonly {
    id: string;
    label: string;
    url: string;
  }[];
  title: string;
}

const TabbedSection = ({tabs, title}: TabbedSectionProps) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [fadeTab, setFadeTab] = useState(tabs[0].id);

  const pageTitle = `${title} \u2014 PeerSignal`;

  return (
    <Page className={classes.fullHeight} title={pageTitle}>
      <Box className={classes.fullHeight}>
        <Box
          pt={1}
          ml={3}
          mr={3}
          className={classes.fullHeight}
          display="flex"
          flexDirection="column"
        >
          <Tabs
            onChange={(_event, value) => {
              // Only set tab if it's a data-driven tab that was passed in
              if (tabs.some(tab => tab.id === value)) {
                setCurrentTab(value);
              }
            }}
            scrollButtons="auto"
            value={currentTab}
            centered
            textColor="secondary"
          >
            {tabs.map(tab => (
              <Tab
                className={classes.tab}
                key={tab.label}
                label={tab.label}
                value={tab.id}
              />
            ))}
            <Tab
              className={classes.tabLink}
              id="createList"
              label="✨ Create Your List -&gt;"
              component={Link}
              href={ExternalLink.KeyplayData}
              underline="none"
              target="_blank"
            />
          </Tabs>
          <Box pt={1} pb={3} height="inherit">
            <Fade
              timeout={350}
              in={currentTab === fadeTab}
              onExited={() => setFadeTab(currentTab)}
            >
              <div style={{height: '100%', position: 'relative'}}>
                {tabs.map(tab => (
                  <div
                    key={tab.id}
                    dangerouslySetInnerHTML={{
                      __html: tab.url,
                    }}
                    style={{
                      visibility: fadeTab === tab.id ? 'visible' : 'collapse',
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                    }}
                  />
                ))}
              </div>
            </Fade>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default TabbedSection;
