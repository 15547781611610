import {AppLink, ExternalLink} from '../nav/common';
import {getIframeFromUrl, getAddCompanyIframe} from './util';

export const IndexData = [
  {
    header: 'B2B SaaS Index',
    content:
      'Study B2B growth strategies and find examples. Explore 1,000+ growing SaaS/Cloud companies.',
    link: AppLink.B2B,
    cta: 'Explore Dataset',
    image: '/b2b_banner.png',
    tabs: [
      {
        id: 'index',
        label: 'SaaS Index',
        url: getIframeFromUrl(
          'https://airtable.com/embed/shrnQkRz0aR6LLZFA?backgroundColor=cyan&viewControls=on'
        ),
      },
      {
        id: 'hiring',
        label: 'SaaS Hiring',
        url: getIframeFromUrl(
          'https://airtable.com/embed/shrg52VpeOzGQ9Da5?backgroundColor=cyan&viewControls=on'
        ),
      },
      {
        id: 'gallery',
        label: 'Gallery',
        url: getIframeFromUrl(
          'https://airtable.com/embed/shrSPbcDlkxAeRhhw?backgroundColor=cyan&viewControls=on'
        ),
      },
      {id: 'add', label: 'Add a Company', url: getAddCompanyIframe()},
    ],
  },
  {
    header: 'Your Ideal Accounts',
    content:
      'Explore the platform behind PeerSignal. Not another database, Keyplay is your new recipe for account research with unique signals, custom scoring, and B2B SaaS focus.',
    link: ExternalLink.ICP,
    cta: 'Learn More',
    image: '/ideal-banner.png',
    tabs: null,
  },
] as const;
