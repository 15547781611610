export enum AppLink {
  Home = '/',
  HiringTracker = '/datasets/hiring-tracker',
  PLG = '/datasets/plg',
  B2B = '/datasets/b2b',
  SaaS = '/datasets/saas',
  AI = '/datasets/ai',
  Fintech = '/datasets/fintech',
}

export enum ExternalLink {
  ICP = 'https://keyplay.io?utm_source=PeerSignal&utm_medium=app_card&utm_campaign=ps_referral',
  Terms = 'https://peersignal.org/terms',
  KeyplayData = 'https://data.keyplay.io/ps',
}
