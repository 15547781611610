import {Analytics, getAnalytics} from 'firebase/analytics';
import {FirebaseApp, initializeApp} from 'firebase/app';
import {Auth, getAuth, User} from 'firebase/auth';
import React, {
  createContext,
  useContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

interface FirebaseContextType {
  app: FirebaseApp;
  auth: Auth;
  analytics: Analytics;
  user: User | null;
}
const FirebaseContext = createContext({} as FirebaseContextType);
export const useFirebaseApp = () => useContext(FirebaseContext);

interface FirebaseProviderProps {
  children: any;
}
const FirebaseProvider: FunctionComponent<FirebaseProviderProps> = props => {
  const [loaded, setLoaded] = useState(false);
  const [app, setApp] = useState<FirebaseApp>({} as FirebaseApp);
  const [auth, setAuth] = useState<Auth>({} as Auth);
  const [analytics, setAnalytics] = useState<Analytics>({} as Analytics);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const app = initializeApp({
      apiKey: 'AIzaSyC6ZVIyNHhlI_QZitx48V4-PAyfbU8tgpQ',
      authDomain: 'peersignalftw.firebaseapp.com',
      projectId: 'peersignalftw',
      storageBucket: 'peersignalftw.appspot.com',
      messagingSenderId: '853601703865',
      appId: '1:853601703865:web:c1955755b9010b8dc24868',
      measurementId: 'G-Z026FN57VH',
    });
    const auth = getAuth(app);
    auth.onAuthStateChanged(newUser => {
      setUser(newUser);
      setLoaded(true);
    });

    setApp(app);
    setAuth(auth);
    setAnalytics(getAnalytics(app));
  }, []);

  if (!loaded) {
    return <React.Fragment />;
  }

  return (
    <FirebaseContext.Provider value={{app, auth, analytics, user}}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
