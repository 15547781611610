import React, {FunctionComponent} from 'react';
import {Container, Grid, Link, makeStyles} from '@material-ui/core';
import Typography from '../shared/components/Typography';
import Page from '../shared/components/Page';
import {useLocation} from 'react-router-dom';
import Quote from '../shared/components/Quote';
import {ExternalLink} from '../nav/common';

const useStyles = makeStyles(theme => ({
  page: {
    marginTop: theme.spacing(8),
  },
  text: {
    marginBottom: theme.spacing(4),
  },
  termsLink: {
    color: theme.palette.secondary.main,
  },
  button: {
    height: 55,
    align: 'center',
  },
  subtitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  quote: {
    marginTop: theme.spacing(6),
    fontSize: 30,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightLight as number,
  },
  italic: {
    fontStyle: 'italic',
  },
}));

const Home: FunctionComponent = () => {
  const classes = useStyles();
  const {loginRedirectPath} = useLocation().state || {};

  return (
    <Page title="PeerSignal" className={classes.page}>
      <Container maxWidth="md">
        <Typography
          gutterBottom
          variant="h4"
          align="center"
          className={classes.text}
        >
          Join or Sign-in to Access Your Free Datasets
        </Typography>
        <Grid container justifyContent="center" direction="row">
          <Link
            href={
              'https://app.peersignal.org/linkedin-auth' +
              (loginRedirectPath
                ? `?loginRedirectPath=${loginRedirectPath}`
                : '')
            }
            className={classes.button}
          >
            <img
              src="/li-large-default.png"
              alt="Sign in with LinkedIn"
              className={classes.button}
            />
          </Link>
        </Grid>
        <Container maxWidth="sm">
          <Typography
            gutterBottom
            variant="body2"
            align="center"
            className={classes.subtitle}
          >
            We use LinkedIn for secure authorization only. We won’t have any
            other permissions. By signing up you agree to our{' '}
            <Link href={ExternalLink.Terms} className={classes.termsLink}>
              terms
            </Link>
            .
            <br />
            <br />
            After signing in, you'll have all free datasets and receive Adam's
            almost-weekly newsletter. Unsubscribe with 1 click anytime.
          </Typography>
        </Container>
        <Quote />
      </Container>
    </Page>
  );
};

export default Home;
