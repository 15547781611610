import React, {FunctionComponent} from 'react';

import {ThemeProvider} from '@material-ui/core/styles';
import {CssBaseline, createTheme} from '@material-ui/core';

import AppLayout from './AppLayout';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import FirebaseProvider from '../shared/providers/FirebaseProvider';
import {common, red, green, blueGrey, grey} from '@material-ui/core/colors';

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#69696a',
      main: '#040820',
      dark: '#1e1e1f',
    },
    secondary: {
      light: common.white, // background of footer and 'how it works' section
      main: '#0E8BED', // call to action buttons, links
      dark: '#0E8BED', // button hover state
    },
    warning: {
      main: '#80deea',
      dark: '#ffb25e',
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
    text: {
      primary: blueGrey[900],
      secondary: blueGrey[600],
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightBold,
  fontFamily: rawTheme.typography.fontFamily,
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      fontSize: 36,
      fontWeight: rawTheme.typography.fontWeightRegular,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 24,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
  },
};

const App: FunctionComponent = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <FirebaseProvider>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="*" element={<AppLayout />} />
            </Routes>
          </Router>
        </FirebaseProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
