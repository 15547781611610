import {Box} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {logEvent} from 'firebase/analytics';
import {
  Route,
  useNavigate,
  useLocation,
  Routes,
  Navigate,
} from 'react-router-dom';
import * as querystring from 'query-string';
import AppNavBar from '../nav/AppNavBar';
import {AppLink} from '../nav/common';
import Dash from '../sections/Dash';
import Home from '../sections/Home';
import {signInWithCustomToken} from 'firebase/auth';
import {useFirebaseApp} from '../shared/providers/FirebaseProvider';
import RequireAuth from '../shared/components/RequireAuth';
import {IndexData} from '../sections/indexData';
import TabbedSection from '../shared/components/TabbedSection';

function AppLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const app = useFirebaseApp();
  const [isLoaded, setIsLoaded] = useState(false);

  // If there's no logged in user and we have a token, block loading until
  // we've tried logging in.
  useEffect(() => {
    if (isLoaded) {
      return;
    }

    const {token, loginRedirectPath} = querystring.parse(location.search);
    if (app.user || !token) {
      setIsLoaded(true);
      return;
    }

    (async () => {
      let pathname: string | undefined;
      try {
        await signInWithCustomToken(app.auth, token as string);
        pathname = loginRedirectPath as string | undefined;
      } catch (err) {
        console.log(`error logging in: ${(err as Error).message}`);
      }

      if (pathname) {
        navigate(pathname);
      }
      setIsLoaded(true);
    })();
  }, [location, navigate, isLoaded, app.user, app.auth]);

  // log google analytics screen view when location changes
  useEffect(() => {
    // https://firebase.google.com/docs/reference/js/v8/firebase.analytics.Analytics#logevent
    logEvent(app.analytics, 'screen_view', {
      firebase_screen: location.pathname,
      firebase_screen_class: 'AppLayout',
      logged_in: !!app.user,
    });
  }, [location.pathname, location.search, app.user, app.analytics]);

  if (!isLoaded) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Box height="100vh" display="flex" flexDirection="column">
        <AppNavBar />
        <Routes>
          {IndexData.map(section =>
            section.tabs ? (
              <Route
                path={section.link}
                element={
                  <RequireAuth>
                    <TabbedSection tabs={section.tabs} title={section.header} />
                  </RequireAuth>
                }
              />
            ) : (
              <></>
            )
          )}
          <Route path={AppLink.Home} element={app.user ? <Dash /> : <Home />} />
          <Route path="*" element={<Navigate to={AppLink.Home} />} />
        </Routes>
      </Box>
    </React.Fragment>
  );
}

export default AppLayout;
