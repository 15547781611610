import React, {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet';

interface PageProps {
  title: string;
}

const Page: FunctionComponent<
  PageProps & React.HTMLAttributes<HTMLDivElement>
> = props => {
  const {title, className} = props;
  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {props.children}
    </div>
  );
};

export default Page;
