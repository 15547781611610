import {FunctionComponent} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {AppLink} from '../../nav/common';
import {useFirebaseApp} from '../providers/FirebaseProvider';

interface RequireAuthProps {
  children: any;
}

// https://ui.dev/react-router-protected-routes-authentication/
const RequireAuth: FunctionComponent<RequireAuthProps> = props => {
  const app = useFirebaseApp();
  const location = useLocation();

  return app.user ? (
    props.children
  ) : (
    <Navigate
      to={{
        pathname: AppLink.Home,
      }}
      state={{loginRedirectPath: location.pathname}}
    />
  );
};

export default RequireAuth;
