import React, {FunctionComponent} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link as MuiLink,
  makeStyles,
} from '@material-ui/core';
import Typography from '../shared/components/Typography';
import Page from '../shared/components/Page';
import {Link as RouterLink} from 'react-router-dom';
import {IndexData} from './indexData';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#ffffff',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardContent: {
    flexGrow: 1,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function getButtonProps(url: string) {
  try {
    new URL(url);
    return {
      component: MuiLink,
      target: '_blank',
      underline: 'none',
      href: url,
    };
  } catch {
    return {
      component: RouterLink,
      to: url,
    };
  }
}

const Dash: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Page title="Datasets &mdash; PeerSignal">
      <Container className={classes.cardGrid} maxWidth="lg">
        {/* End hero unit */}
        <Grid container spacing={4}>
          {IndexData.map(section => (
            <Grid item key={section.header} xs={12} sm={6} md={6} lg={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={section.image}
                  title={section.header}
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h4">
                    {section.header}
                  </Typography>
                  <Typography>{section.content}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    className={classes.button}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    {...getButtonProps(section.link)}
                  >
                    {section.cta}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dash;
