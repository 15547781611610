import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Typography from './Typography';

const useStyles = makeStyles(theme => ({
  grid: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  card: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  cardDetails: {
    flex: 1,
  },
  quote: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium as number,
  },
  highlight: {
    color: '#E61957',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  footer: {
    paddingTop: theme.spacing(2),
    color: '#8C8C8C',
  },
  cardMedia: {
    marginTop: theme.spacing(2),
    width: 84,
    height: 84,
  },
}));

export default function FeaturedPost() {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.grid}>
      <Card className={classes.card}>
        <div className={classes.cardDetails}>
          <CardContent>
            <Typography className={classes.quote} variant="h5">
              "PeerSignal brings real data, in easy-to-understand English, that
              I need to push my company to higher levels of growth.{' '}
              <span className={classes.highlight}>
                My entire exec team looks at the data and it informs our
                day-to-day decisions.
              </span>
              "
            </Typography>
            <Typography
              className={classes.footer}
              variant="subtitle1"
              color="primary"
            >
              <span className={classes.bold}>Jenn Steele</span> | VP Marketing,
              Reprise
            </Typography>
          </CardContent>
        </div>
        <Hidden xsDown>
          <CardMedia
            className={classes.cardMedia}
            image="jenn.jpg"
            title="test"
          />
        </Hidden>
      </Card>
    </Grid>
  );
}
