import React, {FunctionComponent} from 'react';
import clsx from 'clsx';
import AppBar from '../shared/components/AppBar';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Toolbar, makeStyles, Link} from '@material-ui/core';
import {AppLink, ExternalLink} from '../nav/common';
import Typography from '../shared/components/Typography';
import {useFirebaseApp} from '../shared/providers/FirebaseProvider';

const useStyles = makeStyles(theme => ({
  logo: {
    height: 30,
  },
  placeholder: {
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
  left: {
    flex: 1,
  },
  flyoutMenuButton: {
    color: theme.palette.common.white,
    marginLeft: -12,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  flyoutDrawer: {
    width: 256,
  },
}));

const AppNavBar: FunctionComponent = () => {
  const classes = useStyles();
  const app = useFirebaseApp();
  const navigate = useNavigate();

  return (
    <div>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.left} />
          <RouterLink to={AppLink.Home}>
            <img
              src="/PeerSignal_Primary_Logo_White.png"
              alt="PeerSignal logo"
              className={classes.logo}
            />
          </RouterLink>
          <div className={classes.right}>
            {app.user ? (
              <>
                <Typography
                  variant="h6"
                  className={clsx(classes.rightLink, classes.linkSecondary)}
                  component={Link}
                  href={ExternalLink.KeyplayData}
                  underline="none"
                  target="_blank"
                >
                  {'Powered by Keyplay'}
                </Typography>
                <Typography
                  variant="h6"
                  className={clsx(classes.rightLink, classes.linkSecondary)}
                  onClick={async () => {
                    await app.auth.signOut();
                    navigate(AppLink.Home);
                  }}
                >
                  {'Log Out'}
                </Typography>
              </>
            ) : (
              <React.Fragment />
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
};

export default AppNavBar;
