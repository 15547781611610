import React from 'react';
import PropTypes, {InferProps} from 'prop-types';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import MuiTypography, {TypographyProps} from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    markedH2Center: {
      height: 4,
      width: 73,
      display: 'block',
      margin: `${theme.spacing(1)}px auto 0`,
      backgroundColor: theme.palette.secondary.main,
    },
    markedH3Center: {
      height: 4,
      width: 55,
      display: 'block',
      margin: `${theme.spacing(1)}px auto 0`,
      backgroundColor: theme.palette.secondary.main,
    },
    markedH4Center: {
      height: 4,
      width: 55,
      display: 'block',
      margin: `${theme.spacing(1)}px auto 0`,
      backgroundColor: theme.palette.secondary.main,
    },
    markedH6Left: {
      height: 2,
      width: 28,
      display: 'block',
      marginTop: theme.spacing(0.5),
      background: 'currentColor',
    },
  });

function getMarkedClassName(variant: string) {
  switch (variant) {
    case 'h2':
      return 'markedH2Center';
    case 'h3':
      return 'markedH3Center';
    case 'h4':
      return 'markedH4Center';
  }
  return 'markedH6Left';
}

const variantMapping = {
  h1: 'h1',
  h2: 'h1',
  h3: 'h1',
  h4: 'h1',
  h5: 'h3',
  h6: 'h2',
  subtitle1: 'h3',
};

function Typography<C extends React.ElementType>(
  props: TypographyProps<C, {component?: C}> &
    WithStyles<typeof styles> &
    InferProps<typeof Typography.propTypes>
) {
  const {children, variant, classes, marked, ...other} = props;

  return (
    <MuiTypography variantMapping={variantMapping} variant={variant} {...other}>
      {children}
      {marked ? (
        <span className={classes[getMarkedClassName(variant as string)]} />
      ) : null}
    </MuiTypography>
  );
}

Typography.propTypes = {
  marked: PropTypes.oneOf([false, 'center', 'left']),
};

Typography.defaultProps = {
  marked: false,
};

export default withStyles(styles)(Typography);
